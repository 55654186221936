body.nav-open {
  overflow: hidden;
}

@media screen and (min-width: 420px) {
  body.nav-open {
    overflow: auto;
  }
}

.sidebar-toggle {
  position: absolute;
  top: 0.25rem;
  left: 0;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
}

.sidebar-toggle svg {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

html.dark .sidebar-toggle svg,
html.dark .sidebar-footer svg {
  color: white;
  fill: white;
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: 1px;
  padding: 1rem;
  padding-top: 3rem;
  font-size: 14px;
  background: white;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 200ms, transform 200ms;
  border-right: 1px solid #e5e7eb;
  overflow: auto;
}

html.dark .sidebar {
  border-color: #e5e7eb;
}

body.nav-open .sidebar {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

html.dark .sidebar {
  background-color: rgb(15, 23, 42);
  color: white;
}

@media screen and (min-width: 420px) {
  .sidebar {
    width: auto;
    min-width: 340px;
  }
}

.sidebar-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.sidebar-header h2 {
  text-transform: uppercase;
  font-weight: 700;
}

.sidebar-games {
  margin-bottom: 1.5rem;
}

.sidebar-footer {
  margin-top: auto;
}

.sidebar-game {
  margin-bottom: 0.75rem;
  display: block;
}

.sidebar-game .subtitle {
  font-size: 17px;
  font-weight: 700;
}

.sidebar-game span {
  display: block;
}

.sidebar-game span:last-child {
  font-size: 13px;
  line-height: 1;
  opacity: 0.7;
}

.sidebar-close {
  width: 1.5rem;
  height: 1.5rem;
}
