html.dark {
  background-color: rgb(15, 23, 42);
}

/* PLAWIRE ADS: position the ads under the menu */
#pw-oop-right_rail,
#pw-oop-left_rail {
  z-index: 1 !important;
}
.sidebar-container {
  z-index: 9;
}
